// handle base 64 encoding and decoding in server side and client side
// 1. base64Encode
// 2. base64Decode

/**
 * encode the string to base64
 * @param str
 * @returns base64 string
 */
export function base64Encode<T>(obj: T): string {
  if (process.browser) {
    return btoa(JSON.stringify(obj));
  }
  return Buffer.from(JSON.stringify(obj)).toString('base64');
}

export function base64Decode<T>(str: string): T {
  if (process.browser) {
    return JSON.parse(atob(str)) as T;
  }
  return JSON.parse(Buffer.from(str, 'base64')?.toString()) as T;
}

/**
 * Transforms any file into a base64 encoded string
 * @param file The file to be converted to base64
 */
export function fromArrayBufferToBase64(arrayBuffer?: ArrayBuffer) {
  if (!arrayBuffer) {
    return Promise.resolve('');
  }
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(new Blob([arrayBuffer]));
  });
}
